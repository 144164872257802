import axios from 'axios'
// import API_URL from './apiURL'
// const API_URL = 'https://testapi.spildramadronning.dk/'
// const API_URL = 'https://api.spildramadronning.dk/'
import { API_URL } from '../config/apiURL.js'

class AuthService {
  async login (user) {
    const response = await axios.post(API_URL + 'v1/authenticate', {
      username: user.username,
      password: user.password,
    })
    if (response.data.token) {
      localStorage.setItem('token', JSON.stringify(response.data.token))
    }
    return response.data
  }

  logout () {
    localStorage.removeItem('token')
  }

  // for some reason, we need to dot into user, getting the ugly user.user.username construct below.
  // Don't know why this works differently than login above ?
  register (user) {
    return axios.post(API_URL + 'v1/usercreate', {
      usernick: user.user.username,
      usermail: user.user.email,
      password: user.user.password,
    })
  }

  newPassword (user) {
    return axios.post(API_URL + 'v1/resetpassword', {
      usernick: user.username,
      email: user.email,
    })
  }

  setNewPassword (userid, pass, confirmcode) {
    return axios.post(API_URL + 'v1/confirmpassword', {
      userid: userid,
      newpass: pass,
      confirmcode: confirmcode,
    })
  }

  async verifyEmail (userid, confirmcode) {
    const response = await axios.post(API_URL + 'v1/confirmEmail', {
      userid: userid,
      confirmcode: confirmcode,
    })
    return response.data
  }
}

export default new AuthService()
