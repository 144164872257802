import VueI18n from 'vue-i18n'

export function setupAndGetI18n (Vue, isProduction) {
    Vue.use(VueI18n)

    const i18n = new VueI18n({
        locale: 'en',
        fallbackLocale: 'en',
        fallbackRoot: false,
        silentTranslationWarn: true,

        missing (locale, key, vm) {
            // TODO
            return key
        },
    })

    i18n.setLocaleMessage('en', require('../../src/i18n/en.json'))
    i18n.setLocaleMessage('dk', require('../../src/i18n/dk.json'))

    return i18n
}
