/* eslint-disable no-unused-vars */
import axios from 'axios'
import authHeader from './auth-header'
// import API_URL from './apiURL'

import { API_URL } from '../config/apiURL.js'
class UserService {
  getPublicContent () {
    return axios.get(API_URL + 'v1/ping')
  }

  getUserData () {
    return axios.get(API_URL + 'v1/user', { headers: authHeader() })
  }

  getAllUserActionsData () {
    return axios.get(API_URL + 'v1/metrics/userActions/all', {
      headers: authHeader(),
    })
  }

  getUserActionsData (userId) {
    return axios.get(API_URL + ('v1/metrics/userActions/' + userId), {
      headers: authHeader(),
    })
  }

  getUserSessionData () {
    return axios.get(API_URL + ('v1/metrics/sessions/mine'), {
      headers: authHeader(),
    })
  }

  async getUserSessionActionData (sessionId) {
    try {
      const response = await axios.get(API_URL + ('v1/metrics/sessionActions/' + sessionId), {
      headers: authHeader(),
    })
    return response
    } catch (error) {
       console.log(error)
    }
  }

  getUserGroups () {
    return axios.get(API_URL + 'v1/groups/mine', { headers: authHeader() })
  }

  getGroupMembers (groupId) {
    return axios.get(API_URL + ('v1/group/members/' + groupId), {
      headers: authHeader(),
    })
  }

  tokenValid () {
    return axios.get(API_URL + 'v1/tokenValid', { headers: authHeader() })
  }

  async deleteGroup (groupId) {
    const response = await axios.delete(API_URL + 'v1/group/delete', {
      headers: authHeader(),

      data: {
        groupid: groupId,
      },
    })
    window.location.reload()
    return response
  }

  renewToken () {
    return axios.get(API_URL + 'v1/renewtoken', { headers: authHeader() })
  }

  async createNewGroup (groupName) {
    const response = await axios.post(
      API_URL + 'v1/group/create',
      {
        groupname: groupName,
      },
      { headers: authHeader() },
    )
    window.location.reload()
    return response.data
  }
}

export default new UserService()
