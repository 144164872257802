<template>
  <v-footer
    padless
    dark
    width="100%"
  >
    <v-card
      flat
      tile
      class="text-center"
      height="auto"
      width="100%"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-3 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        {{ $t('footer') }}
      </v-card-text>

      <v-divider />

      <v-card-text class="white--text">
        &copy;2020 — <strong>UDG</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data () {
      return {
        icons: [
          'fab fa-facebook',
          'fab fa-discord',
          'fab fa-linkedin',
          'fab fa-instagram',
        ],
      }
    },
  }
</script>
