<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in locales"
        :key="`Lang${i}`"
        :value="lang"
      >
        <span class="locale-name">{{ lang }}</span>
      </option>
    </select>
  </div>
</template>

<script>
  import enIcon from '../assets/flags/en.png'
  import dkIcon from '../assets/flags/dk.png'

  export default {
    name: 'LocaleSwitcher',
    data () {
      return {
        icons: { en: enIcon, dk: dkIcon },

        locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
        langs: ['dk', 'en'],
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
    },
    methods: {
      // <---------------------------
      switchLocale (locale) {
        if (this.$i18n.locale !== locale) {
          this.$i18n.locale = locale
        }
      },
    },
  }
</script>

<style scoped>
li {
  text-decoration: underline;
  color: #459ce7;
  cursor: pointer;
}
</style>
