import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import VueApexCharts from 'vue-apexcharts'

import './plugins/base'
import { setupAndGetI18n } from './config/setup-i18n'

const i18n = setupAndGetI18n(Vue)

Vue.use(VueAxios, axios)
Vue.use(VueChartkick, { adapter: Chart })
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,

  created () {
    const vm = this

    vm.setLanguage('en')
  },
  methods: {
    setLanguage (language) {
      const vm = this

      localStorage.setItem('language', language)

      document.documentElement.lang = language

      vm.$i18n.locale = language

      vm.$vuetify.lang.current = language
    },
  },

  render: h => h(App),
}).$mount('#app')
