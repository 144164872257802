<template>
  <v-app
    id="confirmpasswordchange"
    class="secondary"
  >
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          lg="4"
        >
          <v-card class="elevation-3 pa-3">
            <v-card-text>
              <div class="layout column align-center">
                <img
                  src="../../assets/Ugly_Duckling_Games_PNG.png"
                  alt="Ugly Duckling Games"
                  width="180"
                  height="180"
                >
                <h1 class="flex my-4">
                  Ugly Duckling Games
                </h1>
                <h2
                  v-if="!passChangeconfirmed"
                  class="flex my-4"
                >
                  Enter new password
                </h2>
                <h2
                  v-if="passChangeconfirmed"
                  class="flex my-4"
                >
                  password updated
                </h2>
              </div>
              <v-form v-if="!passChangeconfirmed">
                <v-text-field
                  id="password"
                  v-model="password"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  autocomplete="new-password"
                  :label="$t('password')"
                  :error="error"
                  @click:append="hidePassword = !hidePassword"
                />
              </v-form>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!passChangeconfirmed"
                  block
                  class=" blue darken-1 white--text"
                  @click="SetNewPass"
                >
                  {{ $t('register') }}
                </v-btn>
                <v-btn
                  v-if="passChangeconfirmed"
                  block
                  class=" blue darken-1 white--text"
                  @click="gotoLogin"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import Authservice from '../../services/auth.service'
  export default {
    data: () => ({
      hidePassword: true,
      error: '',
      user: '',
      password: '',
      confirmcode: '',
      passChangeconfirmed: false,
    }),

    mounted () {
      // check with authservice that a password request exists
      // then make the password change
      this.user = this.$route.params.userid
      this.confirmcode = this.$route.params.confirmcode
    },

    methods: {
      SetNewPass () {
        Authservice.setNewPassword(
          this.user,
          this.password,
          this.confirmcode,
        ).then(response => {
          if (response.data.newpassSet === 1) {
            this.passChangeconfirmed = true
          }
        })
      },
      gotoLogin () {
        this.passChangeconfirmed = false
        this.$router.push({ name: 'Login' })
      },
    },
  }
</script>

<style lang="scss" scoped>
#recoverpass {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
</style>
