<template>
  <v-navigation-drawer
    v-model="toggle"
    fixed
    app
  >
    <v-toolbar
      text
      class="toolbar"
      elevation="2"
    >
      <router-link :to="{ name: 'Login' }">
        <img
          src="../../assets/Ugly_Duckling_Games_PNG.png"
          width="36px"
        >
      </router-link>
      <router-link
        :to="{ name: 'Login' }"
        class="text"
      >
        UDG
      </router-link>
    </v-toolbar>
    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item @click="changeRoute('Licens', 1)">
        <v-list-item-action>
          <v-icon>mdi-license</v-icon>
        </v-list-item-action>
        <v-list-item-title
          :class="[{ active: selectedIndex === 1 }, 'item-title']"
        >
          {{ $t('licens') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('Groups', 2)">
        <v-list-item-action>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-title
          :class="[{ active: selectedIndex === 2 }, 'item-title']"
        >
          {{ $t('groups') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('Play', 3)">
        <v-list-item-action>
          <v-icon>mdi-play </v-icon>
        </v-list-item-action>
        <v-list-item-title
          :class="[{ active: selectedIndex === 3 }, 'item-title']"
        >
          {{ $t('play') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('Assignments', 4)">
        <v-list-item-action>
          <v-icon>mdi-text-box-search-outline </v-icon>
        </v-list-item-action>
        <v-list-item-title
          :class="[{ active: selectedIndex === 4 }, 'item-title']"
        >
          {{ $t('assimentsexampels') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="changeRoute('Help', 5)">
        <v-list-item-action>
          <v-icon>mdi-help</v-icon>
        </v-list-item-action>
        <v-list-item-title
          :class="[{ active: selectedIndex === 5 }, 'item-title']"
        >
          {{ $t('help') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    props: {
      toggle: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data () {
      return {
        selectedIndex: 1,

        right: null,
      }
    },
    methods: {
      changeRoute (routeName, selectedIndex) {
        const vm = this

        vm.selectedIndex = selectedIndex

        return vm.$router.push({ name: routeName })
      },
    },
  }
</script>

<style>
.toolbar {
  font-weight: bold;
  font-size: 18px;
}

.toolbar .text {
  padding-left: 15px;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.item-title {
  font-size: 17px;
  font-weight: 500;
}
.item-sub-title {
  font-size: 15px;
  font-weight: 500;
}

.active {
  font-weight: bold;
}
</style>
