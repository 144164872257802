<template>
  <v-app
    id="confirmemail"
    class="secondary"
  >
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          lg="4"
        >
          <v-card class="elevation-3 pa-3">
            <v-card-text>
              <div class="layout column align-center">
                <img
                  src="../../assets/Ugly_Duckling_Games_PNG.png"
                  alt="Ugly Duckling Games"
                  width="180"
                  height="180"
                >
                <h1 class="flex my-4">
                  Ugly Duckling Games
                </h1>
                <h2 class="flex my-4">
                  {{ message }}
                </h2>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import Authservice from '../../services/auth.service'
  export default {
    data: () => ({
      mailConfirmed: false,
      message: 'checking',
    }),

    mounted () {
      this.CheckMailConfirmed(
        this.$route.params.userid,
        this.$route.params.confirmcode,
      )
    },

    methods: {
      CheckMailConfirmed (userid, confirmcode) {
        Authservice.verifyEmail(userid, confirmcode).then(data => {
          if (data.mailconfirmed === 0 || data.mailconfirmed === -1) {
            this.message = 'Invalid link'
          } else if (data.mailconfirmed === 1) {
            this.message = 'Mail confirmed!'
          }
        // this.message = data
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
#recoverpass {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
</style>
