<template>
  <v-app
    id="recoverpass"
    class="secondary"
  >
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          lg="4"
        >
          <v-card class="elevation-3 pa-3">
            <v-card-text>
              <div class="layout column align-center">
                <img
                  src="../../assets/Ugly_Duckling_Games_PNG.png"
                  alt="Ugly Duckling Games"
                  width="180"
                  height="180"
                >
                <h1 class="flex my-4">
                  Ugly Duckling Games
                </h1>
              </div>
              <v-form v-if="!doneSubmitting">
                <v-text-field
                  v-model="user.username"
                  append-icon="person"
                  name="username"
                  :label="$t('username')"
                  type="text"
                  autocomplete="username"
                  :error="error"
                  :rules="[rules.required]"
                />
                <v-text-field
                  v-model="user.email"
                  append-icon="mail"
                  autocomplete=""
                  label="E-mail"
                  :error="error"
                  data-vv-name="email"
                  :rules="[rules.required]"
                />
              </v-form>
              <h2 v-if="doneSubmitting">
                Mail sent. Please follow instructions
              </h2>
              <br>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!doneSubmitting"
                  block
                  class=" blue darken-1 white--text"
                  :loading="loading"
                  @click="HandelNewPassword"
                >
                  new pass
                </v-btn>

                <v-btn
                  v-if="doneSubmitting"
                  block
                  class=" blue darken-1 white--text"
                  :loading="loading"
                  @click="GotoLogin"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import User from '../../models/user'
  import Authservice from '../../services/auth.service'

  export default {
    data: () => ({
      user: new User('', '', ''),
      loading: false,
      message: '',
      error: false,
      showResult: false,
      doneSubmitting: false,
      result: '',
      rules: {
        required: value => !!value || 'Required.',
      },
    }),

    mounted () {
      this.doneSubmitting = false
    },

    methods: {
      HandelNewPassword () {
        const vm = this
        if (!vm.user.username || !vm.user.email) {
          vm.result = "Username and Email can't be null."
          vm.showResult = true

          return
        }
        if (vm.user.username && vm.user.email) {
          Authservice.newPassword(vm.user).then(reply => {
            if (reply.data.newpassRequest === 1) {
              vm.result = 'Success: A mail has been sent to ' + vm.user.email
              vm.showResult = true
              this.doneSubmitting = true
            }
            if (reply.data.newpassRequest === -1) {
              vm.result = 'username and email do not match'
              vm.showResult = true
            }
          })
        }
      },
      GotoLogin () {
        this.doneSubmitting = false
        this.$router.push({ name: 'Login' })
      },
    },
  }
</script>

<style lang="scss" scoped>
#recoverpass {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
</style>
