<template>
  <v-app
    id="login"
    class="secondary"
  >
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          lg="4"
        >
          <v-card class="elevation-3 pa-3">
            <v-card-text>
              <div class="layout column align-center">
                <img
                  src="../../assets/Ugly_Duckling_Games_PNG.png"
                  alt="Ugly Duckling Games"
                  width="180"
                  height="180"
                >
                <h1 class="flex my-4">
                  Ugly Duckling Games
                </h1>
              </div>
              <v-form>
                <v-text-field
                  v-model="user.username"
                  append-icon="person"
                  name="username"
                  autocomplete="username"
                  :label="$t('username')"
                  type="text"
                  :error="isError"
                  :rules="[rules.required]"
                />
                <v-text-field
                  id="password"
                  v-model="user.password"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  :label="$t('password')"
                  autocomplete="current-password"
                  :rules="[rules.required]"
                  :error="isError"
                  @click:append="hidePassword = !hidePassword"
                  @keyup.enter="handleLogin"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                block
                class=" blue darken-1 white--text"
                :loading="loading"
                @click="handleLogin"
              >
                {{ $t('login') }}
              </v-btn>
            </v-card-actions>
            <v-row
              class="pl-3 pr-3"
              justify="center"
              align="center"
            >
              <v-btn
                text
                color="blue darken-1"
                :to="'RecoverPassword'"
              >
                {{ $t('forgotpassword') }}
              </v-btn>
              <v-spacer />
              <v-btn
                text
                color="blue darken-1"
                :to="'Register'"
              >
                {{ $t('register') }}
              </v-btn>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import User from '../../models/user'
  export default {
    name: 'Login',
    data () {
      return {
        user: new User('', ''),
        loading: false,
        message: '',
        hidePassword: true,
        isError: false,
        showResult: false,
        result: '',
        rules: {
          required: value => !!value || 'Required.',
        },
      }
    },
    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created () {
      if (this.loggedIn) {
        this.$router.push({ name: 'Groups' })
      }
    },
    methods: {
      handleLogin () {
        const vm = this
        if (!vm.user.username || !vm.user.password) {
          vm.result = "Email and Password can't be null."
          vm.showResult = true

          return
        }
        this.loading = true
        if (vm.user.username && vm.user.password) {
          this.$store.dispatch('auth/login', vm.user).then(
            () => {
              vm.$router.push({ name: 'Groups' })
            },
            error => {
              this.loading = false
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString()
              vm.error = true
              vm.result = 'Email or Password is incorrect.'
              vm.showResult = true
            },
          )
        }
      },
    },
  }
</script>

<style scoped lang="scss">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
</style>
