import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Error from '../views/core/Error.vue'
import RecoverPassword from '../views/auth/RecoverPassword.vue'
import Register from '../views/auth/CreateUser.vue'
import ConfirmEmail from '../views/auth/ConfirmEmail.vue'
import ConfirmPasswordChange from '../views/auth/ConfirmPasswordChange.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/confirmemail/:userid/:confirmcode',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/confirmPasschange/:userid/:confirmcode',
    name: 'ConfirmPasswordChange',
    component: ConfirmPasswordChange,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/recoverpassword',
    name: 'RecoverPassword',
    component: RecoverPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/licens',
    name: 'Licens',
    // lazy-loaded
    component: () => import('../views/Licens.vue'),
  },
  {
    path: '/groups',
    name: 'Groups',
    // lazy-loaded
    component: () => import('../views/Group.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test.vue'),
  },
  {
    path: '/assignments',
    name: 'Assignments',
    component: () => import('../views/Assimnents.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    beforeEnter (to, from, next) {
      window.open(
        'https://teacher.dramastudio.net/pdfs/Groups_in_DRAMA_STUDIO-HOWTO.pdf',
        '_blank',
      )
    },
  },
  {
    path: '/play',
    name: 'Play',
    beforeEnter (to, from, next) {
      window.open(' https://play.dramastudio.net', '_blank')
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
