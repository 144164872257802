import AuthService from '../services/auth.service'

const token = JSON.parse(localStorage.getItem('token'))
const initialState = token
  ? { status: { loggedIn: true }, token }
  : { status: { loggedIn: false }, token: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, user) {
      return AuthService.login(user).then(
        token => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        },
      )
    },
    logout ({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    register ({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        },
      )
    },
    newPassword ({ commit }, user) {
      AuthService.newPassword(user).then(
         response => {
          commit('newpassSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('newpassFailure')
          return Promise.reject(error)
        },
       )
    },
  },
  mutations: {
    loginSuccess (state, token) {
      state.status.loggedIn = true
      state.token = token
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.token = null
    },
    logout (state) {
      state.status.loggedIn = false
      state.token = null
    },
    registerSuccess (state) {
      state.status.loggedIn = false
    },
    registerFailure (state) {
      state.status.loggedIn = false
    },
    newpassSuccess (state) {
      state.status.loggedIn = false
    },
    newpassFailure (state) {
      state.status.loggedIn = false
    },
  },
}
