export default function authHeader () {
    // return authorization header with jwt token
    const token = JSON.parse(localStorage.getItem('token'))

    if (token) {
        return { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    } else {
        return {}
    }
}
