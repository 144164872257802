<template>
  <v-app
    id="newuser"
    class="secondary"
  >
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
          lg="4"
        >
          <v-card class="elevation-3 pa-3">
            <v-card-text>
              <div class="layout column align-center">
                <img
                  src="../../assets/Ugly_Duckling_Games_PNG.png"
                  alt="Ugly Duckling Games"
                  width="180"
                  height="180"
                >
                <h1 class="flex my-4">
                  Ugly Duckling Games
                </h1>
              </div>
              <v-form @keyup.enter="onEnter">
                <v-text-field
                  v-model="user.username"
                  append-icon="person"
                  name="username"
                  :label="$t('username')"
                  autocomplete="username"
                  type="text"
                  :error="error"
                  :rules="[rules.required]"
                />
                <v-text-field
                  v-model="user.email"
                  append-icon="mail"
                  label="E-mail"
                  :error="error"
                  data-vv-name="email"
                  :rules="[rules.required]"
                />
                <v-text-field
                  id="password"
                  v-model="user.password"
                  :type="hidePassword ? 'password' : 'text'"
                  :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                  name="password"
                  autocomplete="new-password"
                  :label="$t('password')"
                  :rules="[rules.required]"
                  :error="error"
                  @click:append="hidePassword = !hidePassword"
                />
              </v-form>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  block
                  class=" blue darken-1 white--text"
                  :loading="loading"
                  @click="HandelNewUser"
                >
                  {{ $t('register') }}
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import User from '../../models/user'
  export default {
    setup () {
      return {}
    },
    data: () => ({
      user: new User('', '', ''),
      loading: false,
      message: '',
      hidePassword: true,
      error: false,
      showResult: false,
      result: '',
      rules: {
        required: value => !!value || 'Required.',
      },
    }),
    methods: {
      HandelNewUser () {
        const vm = this
        if (!vm.user.username || !vm.user.password || !vm.user.email) {
          vm.result = "Username, Email and Password can't be null."
          vm.showResult = true

          return
        }
        if (vm.user.username && vm.user.password && vm.user.email) {
          this.$store.dispatch('auth/register', vm).then(
            () => {
              vm.$router.push({ name: 'Login' })
            },
            error => {
              this.loading = false
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString()
              vm.error = true
              vm.result = 'Email or Password is incorrect.'
              vm.showResult = true
            },
          )
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
#newuser {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
}
</style>
