<template>
  <v-app-bar app>
    <v-app-bar-nav-icon
      text
      large
      @click="toggleNavigationBar"
    />

    <v-spacer />
    <v-btn
      text
      :to="'Profile'"
    >
      {{ username }}
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <v-btn
      v-if="this.$store.state.auth.status.loggedIn"
      class="black--text text--lighten-1"
      @click="logout"
    >
      {{ $t('logout') }}
      <v-icon>mdi-lock</v-icon>
    </v-btn>
    <locale-switcher />

    <v-snackbar
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
  </v-app-bar>
</template>
<script>
  import UserService from '@/services/user.service'
  import LocaleSwitcher from '@/components/LocaleSwitcher'

  export default {
    components: { LocaleSwitcher },
    data () {
      return {
        error: false,
        showResult: false,
        username: '',
        result: '',
        usermenu: [{ title: 'logout', icon: 'mdi-lock' }],
      }
    },
    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    mounted () {
      if (!this.loggedIn) {
        this.$router.push('/login')
      } else {
        UserService.getUserData().then(
          response => {
            this.content = response.data
            this.username = this.content._nickname
          },
          error => {
            this.content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          },
        )
      }
    },
    methods: {
      toggleNavigationBar () {
        const vm = this
        vm.$emit('toggleNavigationBar')
      },
      logout () {
        const vm = this
        this.$store.dispatch('auth/logout', vm).then(() => {
          vm.$router.push({ name: 'Login' })
        })
      },
    },
  }
</script>
<style></style>
